import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import CloudflareImage from "../../components/reusable-components/cloudflare-image"
import { ReactSVG } from "react-svg"

import Layout from "../../components/theme/layout"
import SEO from "../../components/theme/header/seo"

import LinkWrapper from "../../components/reusable-components/link-wrapper"
import FlexSection from "../../components/reusable-components/layout/flex-section"

import { getProperty } from "../../lib/utils"

const AllBrandsPage = ({ data, pageContext, location }) => {
  const title = getProperty(data, ["wpProductCategory", "name"], "Brands")
  const yoast = getProperty(data, ["seaCategory", "seo"], {})
  const brandsPageSlug = getProperty(
    data,
    ["wpProductCategory", "slug"],
    "brands"
  )
  const description = getProperty(
    data,
    ["wpProductCategory", "description"],
    ""
  )
  const brands = getProperty(data, ["brands", "nodes"], [])
  return (
    <Layout pathContext={pageContext} location={location}>
      <SEO
        title={getProperty(data, ["seaCategory", "seo", "title"], "")}
        location={location}
        yoast={yoast}
      />
      <H1Styling dangerouslySetInnerHTML={{ __html: title }} />
      <H2Styling dangerouslySetInnerHTML={{ __html: description }} />
      <AllBrandsCardsFlexSectionStyling direction="row">
        {brands.map((node) => {
          const name = getProperty(node, ["name"], "")
          const link = getProperty(node, ["slug"], "")
          const productCount = getProperty(node, ["products", "nodes"], "")
          const brandImage = getProperty(node, ["image"], "")
          const brandLogo = getProperty(
            node,
            [
              "acf_product_categories_and_brands",
              "brandLogo",
              "localFile",
              "publicURL",
            ],
            ""
          )
          if (!name || !link || typeof link == "undefined") {
            return null
          }
          const brandImageFluid = getProperty(brandImage, ["sourceUrl"], false)
          return (
            <BrandCardStyling key={link} to={`/${brandsPageSlug}/${link}`}>
              {!brandImage ? (
                <EmptyImageStyling />
              ) : (
                <StyledImg
                  objectFit="cover"
                  height="330px"
                  src={brandImageFluid}
                />
              )}
              {!brandLogo ? null : <StyledReactSVG src={brandLogo} />}
              <H3Styling>{name}</H3Styling>
              {typeof productCount == "undefined" ? null : (
                <ProductCountStyling>{`${productCount.length} Products`}</ProductCountStyling>
              )}
            </BrandCardStyling>
          )
        })}
      </AllBrandsCardsFlexSectionStyling>
    </Layout>
  )
}

export default AllBrandsPage

// ===============
//     STYLES
// ===============
const H1Styling = styled.h1`
  margin: 0;
`
const H2Styling = styled.h2`
  font-size: 1.333rem;
  font-weight: 400;
  line-height: 1.67;
  margin-bottom: 6rem;
  text-transform: none;
`
const H3Styling = styled.h3`
  font-size: 1.333rem;
  line-height: 2;
  font-weight: 700;
  margin: 10px 0 0 20px;
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    margin: 10px 0 0 0;
    text-align: center;
  }
`
const ProductCountStyling = styled.div`
  color: ${({ theme }) => theme.colors.black1};
  font-style: italic;
  margin-left: 20px;
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    margin-left: 0;
    text-align: center;
  }
`

const StyledImg = styled(CloudflareImage)`
  z-index: -1;
  height: 330px;
`
const EmptyImageStyling = styled.div`
  height: 330px;
`
const StyledReactSVG = styled(ReactSVG)`
  :not(:first-child) {
    margin-top: -60px;
  }
  background-color: ${({ theme }) => theme.colors.white}66;
  height: 60px;
  & > div {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  & svg {
    margin: 0 auto;
    width: 100%;
    max-height: 50px;
  }
`
const BrandCardStyling = styled(LinkWrapper)`
  width: calc(calc(100% - calc(20px * 3)) / 4);
  height: 460px;
  @media (min-width: ${({ theme }) => theme.breakMedium}) {
    :not(:nth-child(4n + 4)) {
      margin-right: 20px;
    }
  }
  overflow: hidden;
  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    width: calc(calc(100% - calc(20px * 1)) / 2);
    :not(:nth-child(2n + 2)) {
      margin-right: 20px;
    }
  }
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    width: 100%;
    :not(:nth-child(2n + 2)) {
      margin-right: 0px;
    }
  }
`
const AllBrandsCardsFlexSectionStyling = styled(FlexSection)`
  flex-wrap: wrap;
  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    flex-direction: row;
  }
`

// ===============
//      QUERY
// ===============
export const allBrandsQueryData = graphql`
  query GET_ALL_BRANDS_PAGE_QUERY($brandsCategoryId: Int!) {
    wpProductCategory(databaseId: { eq: $brandsCategoryId }) {
      name
      description
      slug
    }
    brands: allWpProductCategory(
      sort: { fields: name, order: ASC }
      filter: { parentDatabaseId: { eq: $brandsCategoryId } }
    ) {
      nodes {
        databaseId
        name
        slug
        products {
          nodes {
            productId: databaseId
          }
        }
        image {
          sourceUrl
        }
        acf_product_categories_and_brands {
          brandLogo {
            localFile {
              publicURL
            }
          }
        }
      }
    }
    seaCategory(database_id: { eq: $brandsCategoryId }) {
      seo {
        description
        meta_description
        og_description
        og_image
        og_title
        og_type
        site_name
        title
        twitter_card
        twitter_creator
        twitter_description
        twitter_title
      }
    }
  }
`
